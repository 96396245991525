/**
 *  function  that cancel the scrolling in the page if the value is true
 * @param {boolean} turnOn
 */

export const cancel_scrolling = (turnOn) => {
  const htmlBody = document.getElementsByTagName("body")[0];
  if (turnOn) {
    htmlBody.style.overflow = "hidden";
  } else {
    htmlBody.style.overflow = "auto";
  }
};

/**
 * Convert client type to a readable text.
 * @param {number} clientType
 * @returns {string}
 */
export const getClientTypeText = (clientType) => {
  switch (clientType) {
    case 1:
      return "חברה פרטית";
    case 2:
      return "חברה ציבורית";
    case 3:
      return "עוסק מורשה";
    default:
      return "סוג לא ידוע";
  }
};

// Utility function to format number with commas
export const formatNumberWithCommas = (number) => {
  return new Intl.NumberFormat().format(number);
};

export const carTypes = [
  { value: "all", label: "כל סוגי הרכב" },
  { value: "אוטובוס", label: "אוטובוס" },
  { value: "מונית", label: "מונית" },
  { value: "מידיבוס", label: "מידיבוס" },
  { value: "מיניבוס", label: "מיניבוס" },
  { value: "מעלון", label: "מעלון" },
  { value: "רכב 10", label: "רכב 10" },
  { value: "רכב 14", label: "רכב 14" },
  { value: "רכב 16", label: "רכב 16" },
];

export const calculatePriceWithVAT = (price, vatRate = 0.17) => {
  return price + price * vatRate;
};

export const formatDate = (dateString) => {
  const [year, month, day] = dateString.split(" ")[0].split("-");
  return `${day}.${month}.${year}`;
};
