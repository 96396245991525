import React, { Fragment, useEffect, useState, useCallback } from "react";
import { Table, Pagination, OverlayTrigger, Tooltip } from "react-bootstrap";
import "../trips.scss";
import LoaderComponent from "../../../components/LoaderComponent/LoaderComponent";
import classNames from "classnames";
import { useLocation, useNavigate } from "react-router-dom";
import { fetchData } from "../../../utils/fetchData";
import {
  calculatePriceWithVAT,
  carTypes,
  formatDate,
  formatNumberWithCommas,
} from "../../../utils/supportFunctions";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const TripsTable = ({ loading }) => {
  const navigate = useNavigate();
  const location = useLocation();

  // Retrieve saved state from sessionStorage or use default values
  const getSavedState = (key, defaultValue) => {
    try {
      const savedValue = sessionStorage.getItem(key);
      return savedValue ? JSON.parse(savedValue) : defaultValue;
    } catch (error) {
      return defaultValue;
    }
  };
  // State with preservation
  const [currentPage, setCurrentPage] = useState(
    getSavedState("currentPage", 1)
  );
  const [trips, setTrips] = useState([]);
  const [filteredTrips, setFilteredTrips] = useState([]);
  const [searchTerm, setSearchTerm] = useState(getSavedState("searchTerm", ""));
  const [statusFilter, setStatusFilter] = useState(
    getSavedState("statusFilter", "all")
  );
  const [carTypeFilter, setCarTypeFilter] = useState(
    getSavedState("carTypeFilter", "all")
  );
  const [startDate, setStartDate] = useState(getSavedState("startDate", null));
  const [endDate, setEndDate] = useState(getSavedState("endDate", null));
  const [selectedTime, setSelectedTime] = useState(
    getSavedState("selectedTime", "")
  );
  const [selectedCompany, setSelectedCompany] = useState(
    getSavedState("selectedCompany", "")
  );

  const [userData] = useState(
    JSON.parse(localStorage.getItem("userData")) || null
  );

  // Save state to sessionStorage whenever it changes
  useEffect(() => {
    // Create a function to safely save state
    const saveState = (key, value) => {
      try {
        if (value !== null && value !== undefined) {
          sessionStorage.setItem(key, JSON.stringify(value));
        } else {
          sessionStorage.removeItem(key);
        }
      } catch (error) {
        console.error("Error saving state", error);
      }
    };

    // Save all filter states
    saveState("currentPage", currentPage);
    saveState("searchTerm", searchTerm);
    saveState("statusFilter", statusFilter);
    saveState("carTypeFilter", carTypeFilter);
    saveState("startDate", startDate);
    saveState("endDate", endDate);
    saveState("selectedTime", selectedTime);
    saveState("selectedCompany", selectedCompany);
  }, [
    currentPage,
    searchTerm,
    statusFilter,
    carTypeFilter,
    startDate,
    endDate,
    selectedTime,
    selectedCompany,
  ]);

  useEffect(() => {
    const scrollPosition = sessionStorage.getItem("scrollPosition");
    if (scrollPosition) {
      window.scrollTo(0, parseInt(scrollPosition, 10));
      sessionStorage.removeItem("scrollPosition");
    }
  }, []);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    fetchStations();
  }, [startDate, endDate]);

  const fetchStations = async () => {
    try {
      if (!userData.token) {
        throw new Error("User is not authenticated");
      }

      const fromDate = startDate
        ? new Date(startDate.getTime() - startDate.getTimezoneOffset() * 60000)
            .toISOString()
            .split("T")[0]
        : "";

      const toDate = endDate
        ? new Date(endDate.getTime() - endDate.getTimezoneOffset() * 60000)
            .toISOString()
            .split("T")[0]
        : "";

      let dataObj = {
        request: "trips/admin",
        fromDateTime: fromDate,
        toDateTime: toDate,
        driverExists: "",
      };

      const response = await fetchData(dataObj, "POST", userData.token);
      setTrips(response.data.trips);
      setFilteredTrips(response.data.trips);
    } catch (err) {
      // Handle errors
      // console.error(err)
    }
  };

  const filterTrips = useCallback(() => {
    const filtered = trips.filter((trip) => {
      const matchesSearchTerm =
        trip.lineDescription.toLowerCase().includes(searchTerm.toLowerCase()) ||
        formatDate(trip.orderStartTime.split(" ")[0]).includes(searchTerm);

      //update for seraching cancelled trip
      const isCancelled =
        trip.isActive === "0" || trip.lineDescription.includes("נסיעה בוטלה");

      const matchesStatusFilter =
        (statusFilter === "all" && !isCancelled) ||
        (statusFilter === "cancelled" &&
          (trip.isActive === "0" ||
            trip.lineDescription.includes("נסיעה בוטלה"))) ||
        (statusFilter === "open" &&
          trip.isActive === "1" &&
          !trip.driverNickName &&
          !isCancelled) ||
        (statusFilter === "inProgress" &&
          trip.isActive === "1" &&
          trip.driverNickName &&
          !isCancelled &&
          trip.clientPrice > 0) ||
        (statusFilter === "missingFields" &&
          trip.isActive === "1" &&
          !trip.driverNickName &&
          getMissingFields(trip).length > 0);

      const matchesCarTypeFilter =
        carTypeFilter === "all" || trip.orderCarType === carTypeFilter;

      const tripDate = new Date(trip.orderStartTime.split(" ")[0]);

      //update for the fetching the same date data
      const matchesDateRange =
        (!startDate || tripDate >= startDate.setHours(0, 0, 0, 0)) &&
        (!endDate || tripDate <= endDate.setHours(23, 59, 59, 999));

      const matchesTimeFilter =
        !selectedTime ||
        new Date(trip.orderStartTime).getHours() ===
          parseInt(selectedTime.split(":")[0]);

      const matchesCompanyFilter =
        !selectedCompany || trip.clientName === selectedCompany;

      return (
        matchesSearchTerm &&
        matchesStatusFilter &&
        matchesCarTypeFilter &&
        matchesDateRange &&
        matchesTimeFilter &&
        matchesCompanyFilter
      );
    });

    setFilteredTrips(filtered);
  }, [
    searchTerm,
    statusFilter,
    carTypeFilter,
    trips,
    startDate,
    endDate,
    selectedTime,
    selectedCompany,
  ]);

  useEffect(() => {
    filterTrips();
  }, [
    searchTerm,
    statusFilter,
    carTypeFilter,
    startDate,
    endDate,
    selectedTime,
    selectedCompany,
    filterTrips,
  ]);

  const displayTableState = () => {
    return (
      <div className="custom-table-container">
        <div className="mt-3 d-flex flex-column justify-content-center align-items-center">
          <div>
            <p className="lead text-darker font-weight-black text-center mb-0">
              לא נמצאו תוצאות
            </p>
            <p className="text-muted text-center mb-4">
              נסה להשתמשים בערכים אחרים בחיפוש
            </p>
          </div>
        </div>
      </div>
    );
  };

  const handleRowClick = (id) => {
    sessionStorage.setItem("scrollPosition", window.scrollY.toString());
    navigate(`/dashboard/trip-details/${id}`);
  };

  const getMissingFields = (trip) => {
    const missingFields = [];
    if (!trip.clientPrice || trip.clientPrice <= 0) {
      missingFields.push("מחיר נהג");
    }
    if (!trip.orderCarType) {
      missingFields.push("סוג רכב");
    }
    // if (!trip.lineType) {
    //   missingFields.push("סוג נסיעה");
    // }
    return missingFields;
  };

  return (
    <Fragment>
      <div className="row mb-3 g-2">
        <div className="col-12 col-md-12">
          <input
            type="text"
            className="form-control"
            placeholder="חפש לפי מלל או עיר לדוגמא: תל אביב"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        <div className="col-12 col-md-3">
          <select
            className="form-select"
            value={statusFilter}
            onChange={(e) => setStatusFilter(e.target.value)}
          >
            <option value="all">כל הנסיעות</option>
            <option value="cancelled">נסיעות שבוטלו</option>
            <option value="open">נסיעות פתוחות</option>
            <option value="inProgress">נסיעות סגורות</option>
            <option value="missingFields">נסיעות עם חוסרים</option>
          </select>
        </div>
        <div className="col-12 col-md-3">
          <select
            className="form-select"
            value={carTypeFilter}
            onChange={(e) => setCarTypeFilter(e.target.value)}
          >
            {carTypes.map((carType) => (
              <option key={carType.value} value={carType.value}>
                {carType.label}
              </option>
            ))}
          </select>
        </div>
        <div className="col-12 col-md-2">
          <DatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            dateFormat="dd/MM/yyyy"
            className="form-control custom-datepicker"
            placeholderText="תאריך התחלה"
            isClearable
          />
        </div>
        <div className="col-12 col-md-2">
          <DatePicker
            selected={endDate}
            onChange={(date) => setEndDate(date)}
            dateFormat="dd/MM/yyyy"
            className="form-control custom-datepicker"
            placeholderText="תאריך סיום"
            isClearable
          />
        </div>
        {/* Time Filter */}
        <div className="col-12 col-md-2">
          <select
            className="form-select"
            value={selectedTime}
            onChange={(e) => setSelectedTime(e.target.value)}
          >
            <option value="">בחר שעה</option>
            {Array.from({ length: 24 }, (_, index) => (
              <option
                key={index}
                value={index.toString().padStart(2, "0") + ":00"}
              >
                {index.toString().padStart(2, "0")}:00
              </option>
            ))}
          </select>
        </div>
        {/* Company Filter */}
        <div className="col-12 col-md-3">
          <select
            className="form-select"
            value={selectedCompany}
            onChange={(e) => setSelectedCompany(e.target.value)}
          >
            <option value="">בחר חברה מציעה</option>
            {[...new Set(trips.map((trip) => trip.clientName))].map(
              (company) => (
                <option key={company} value={company}>
                  {company}
                </option>
              )
            )}
          </select>
        </div>
      </div>

      {filteredTrips?.length > 0 ? (
        <div className="custom-table-container">
          <div className="table-responsive">
            <Table responsive className="table-hover">
              <thead className="thead-light sticky-header">
                <tr className="font-weight-bold">
                  <th>תאריך</th>
                  <th>
                    שעת
                    <div>התחלה</div>
                  </th>
                  <th>
                    שעת
                    <div>סיום</div>
                  </th>
                  <th>תיאור הקו</th>
                  <th>
                    חברה
                    <div>מציעה</div>
                  </th>
                  <th>
                    חברה
                    <div>מבצעת</div>
                  </th>
                  <th>
                    הערות
                    <div>נסיעה</div>
                  </th>
                  <th>
                    מחיר
                    <div>כולל מע״מ</div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {filteredTrips.map((item) => {
                  const missingFields = getMissingFields(item);
                  const numOfPassengers = item.stations.reduce(
                    (total, station) => total + station.passengers.length,
                    0
                  );

                  return (
                    <tr
                      key={item.lineCode}
                      className={classNames(
                        "table-row custom-regular-background",
                        item.driverNickName && item.isActive === "1"
                          ? "table-info-static"
                          : "",
                        item.isActive === "0" ||
                          item.lineDescription.includes("נסיעה בוטלה")
                          ? "custom-danger-background line-through"
                          : "",
                        missingFields.length > 0 ? "error-color-class" : ""
                      )}
                      onClick={() => handleRowClick(item.lineCode)}
                      style={{ cursor: "pointer" }}
                    >
                      <td>{formatDate(item.orderStartTime.split(" ")[0])}</td>
                      <td>{item.orderStartTime.split(" ")[1]}</td>
                      <td>{item.orderEndTime.split(" ")[1]}</td>
                      <td>
                        <div>
                          {item.lineDescription.slice(0, 60)}
                          {item.lineDescription.length > 60 && "..."}
                        </div>
                        <div className="text-muted">
                          נוסעים: {numOfPassengers} | תחנות:{" "}
                          {item.stations.length} | סוג רכב: {item.orderCarType}
                        </div>
                        {missingFields.length > 0 && (
                          <div className="text-danger">
                            חסר: {missingFields.join(", ")}
                          </div>
                        )}
                      </td>
                      <td>{item.clientName}</td>
                      <td>{item.driverNickName ? item.driverNickName : "-"}</td>
                      <td>
                        {item.shortRemarks ? (
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip id={`tooltip-${item.lineCode}`}>
                                {item.shortRemarks}
                              </Tooltip>
                            }
                          >
                            <span className="text-truncated">
                              {item.shortRemarks.slice(0, 20)}
                              {item.shortRemarks.length > 20 && "..."}
                            </span>
                          </OverlayTrigger>
                        ) : (
                          "-"
                        )}
                      </td>
                      <td>
                        {item.clientPrice > 0 ? (
                          <span className="font-weight-bold">
                            {formatNumberWithCommas(
                              calculatePriceWithVAT(item.clientPrice)
                            )}{" "}
                            ₪
                          </span>
                        ) : (
                          "-"
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </div>
      ) : (
        displayTableState()
      )}
    </Fragment>
  );
};

export default TripsTable;
