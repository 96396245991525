import React, { useState } from "react";
import { Navbar, Nav } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { navItems } from "../SideMenu/navItems";
import { handleLogout } from "../SideMenu/authHelpers";
import Logo from "../../assets/icons/logo.svg";
import { FaBars } from "react-icons/fa";
import "./mobileTopMenu.scss";

const MobileTopMenu = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [expanded, setExpanded] = useState(false);

  const handleToggle = () => setExpanded(!expanded);
  const handleClose = () => setExpanded(false);

  return (
    <Navbar
      variant="dark"
      expand="lg"
      className="d-md-none navbar"
      sticky="top"
      expanded={expanded}
    >
      <Navbar.Brand as={Link} to="/dashboard">
        <img
          src={Logo}
          alt="logo"
          style={{ width: "150px" }}
          className="img-thumbnail mx-auto d-block mb-2"
        />
      </Navbar.Brand>
      {/* Custom Hamburger Icon */}
      <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={handleToggle}>
        <FaBars size={24} className="custom-hamburger-icon" />
      </Navbar.Toggle>
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          {navItems.map((item, index) => (
            <Nav.Link
              key={index}
              as={Link}
              to={item.to}
              onClick={handleClose}
              className="nav-link"
            >
              {item.icon} {item.label}
            </Nav.Link>
          ))}
          <Nav.Link
            onClick={() => {
              handleLogout(navigate, setLoading);
              handleClose();
            }}
            className="logout-link"
          >
            התנתק
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default MobileTopMenu;
