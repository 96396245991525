import { apiUrl } from "./baseValues";
import axios from "axios";

export async function fetchData(
  requestObject,
  method = "POST",
  token = null,
  params = {},
  navigate = null // Accept navigate as a parameter
) {
  try {
    if (navigator.onLine) {
      const env = process.env.REACT_APP_ENV || "development";
      const apiEndpoint = apiUrl[env];
      const fullUrl = `${apiEndpoint}${requestObject.request}`;

      const config = {
        method: method,
        url: fullUrl,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      if (method === "POST" || method === "PUT") {
        if (requestObject.body) {
          if (requestObject.body instanceof FormData) {
            // Do not set Content-Type header for multipart/form-data
            config.data = requestObject.body;
          } else {
            config.headers["Content-Type"] = "application/json";
            config.data = requestObject.body;
          }
        } else {
          config.headers["Content-Type"] = "application/json";
          config.data = requestObject;
        }
      } else if (method === "GET") {
        config.params = params;
      }

      const response = await axios(config);
      const data = response.data;
      if (data.rc === "rc_success") {
        return data;
      } else if (data.rc === "rc_token_expire") {
        console.log("rc_token_expire");
        return;
      } else {
        return data;
      }
    } else {
      console.warn("No internet connection.");
      throw new Error("No internet connection.");
    }
  } catch (error) {
    console.error("Error:", error);

    // Handle 401 error
    if (error.response && error.response.status === 401) {
      console.log("Unauthorized, redirecting to login...");
      localStorage.clear();
      if (navigate) {
        navigate("/login");
      } else {
        window.location.href = "/login";
      }
      throw new Error("Unauthorized. Redirecting to login.");
    }

    if (error.response && error.response.data) {
      throw new Error(error.response.data);
    } else {
      throw new Error("An unknown error occurred.");
    }
  }
}
