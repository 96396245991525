import React from "react";
import { Container } from "react-bootstrap";

export default function EnvironmentHeader() {
  const hostname = window.location.hostname.toLowerCase();

  const isProduction =
    hostname.endsWith("backoffice.weelz.co.il") && !hostname.includes("test");
  return (
    <Container
      fluid
      className={`shadow-sm p-2 mb-1 ${
        isProduction ? "env_header bg-yellow" : "env_header bg-danger"
      }`}
    >
      <div className="d-flex justify-content-center align-items-center">
        <p className="m-0 p-0 font-weight-bold">
          {isProduction
            ? "ווילז - מערכת ניהול"
            : "ווילז מערכת ניהול - סביבת בדיקות!"}
        </p>
      </div>
    </Container>
  );
}
