import React from "react";
import { MdSpaceDashboard, MdComputer } from "react-icons/md";
import { BsFillPersonVcardFill } from "react-icons/bs";
import { FiSettings } from "react-icons/fi";
import { FaBus } from "react-icons/fa";

export const navItems = [
  {
    to: "/dashboard",
    icon: <MdSpaceDashboard />,
    label: "לוח הבקרה",
    end: true,
  },
  {
    to: "/dashboard/clients",
    icon: <MdComputer />,
    label: "לקוחות",
  },
  {
    to: "/dashboard/trips",
    icon: <FaBus />,
    label: "נסיעות",
  },
  {
    to: "/dashboard/settings",
    icon: <FiSettings />,
    label: "הגדרות",
  },
];
