import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { fetchData } from "../../../utils/fetchData";

export const CreateStationModal = ({
  isOpen,
  setIsOpen,
  fetchClients,
  setToastType,
  setToastMessage,
  setShowToast,
  stations,
  client,
  setClient,
}) => {
  const [clientName, setClientName] = useState("");
  const [llsNumber, setLlsNumber] = useState("");
  const [clientBillingEmail, setClientBillingEmail] = useState("");
  const [clientType, setClientType] = useState(1);

  const [clientNameError, setClientNameError] = useState(false);
  const [clientIDError, setClientIDError] = useState(false);
  const [clientBillingEmailError, setClientBillingEmailError] = useState("");
  const [emailErrorMessage, setEmailErrorMessage] = useState("");

  useEffect(() => {
    if (client) {
      setClientName(client.name);
      setClientType(client.clientType);
      setLlsNumber(client.llsNumber);
      setClientBillingEmail(client.billingEmail);
    } else {
      setClientName("");
      setClientType(1);
      setLlsNumber("");
      setClientBillingEmail("");
    }
  }, [client]);

  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission

    // Validate each field
    const isValid = validateForm();
    if (!isValid) {
      return;
    }

    if (client) {
      await updateClient();
    } else {
      await addNewStation();
    }
  };

  // Form validation function
  const validateForm = () => {
    let valid = true;

    if (!clientName) {
      setClientNameError(true);
      valid = false;
    } else {
      setClientNameError(false);
    }

    if (!llsNumber) {
      setClientIDError(true);
      valid = false;
    } else {
      setClientIDError(false);
    }

    if (!clientBillingEmail) {
      setClientBillingEmailError(true);
      setEmailErrorMessage("שדה חובה");
      valid = false;
    } else if (!/\S+@\S+\.\S+/.test(clientBillingEmail)) {
      setClientBillingEmailError(true);
      setEmailErrorMessage("כתובת הדוא״ל אינה תקינה");
      valid = false;
    } else {
      setClientBillingEmailError(false);
      setEmailErrorMessage("");
    }

    return valid;
  };

  // ADD A NEW client
  const addNewStation = async () => {
    try {
      const userData = localStorage.getItem("userData");
      if (!userData) {
        throw new Error("User is not authenticated");
      }

      const token = JSON.parse(userData).token;
      if (!token) {
        throw new Error("User is not authenticated");
      }

      const requestObject = {
        request: "clients/register",
        name: clientName,
        clientType: clientType,
        llsNumber: llsNumber,
        billingEmail: clientBillingEmail,
      };

      await fetchData(requestObject, "POST", token);
      setToastType("success");
      setToastMessage("הלקוח נוסף בהצלחה");
      setShowToast(true);
      setIsOpen(false);
      fetchClients();
      setClientName("");
      setLlsNumber("");
      setClientType(1);
      setClientBillingEmail("");
    } catch (err) {
      console.error("Error adding new client:", err.message);
      setToastType("danger");
      setToastMessage("לא ניתן להוסיף לקוח");
      setShowToast(true);
      setClientName("");
      setLlsNumber("");
      setClientType(1);
      setClientBillingEmail("");
    }
  };

  // FUNCTION TO UPDATE client
  const updateClient = async () => {
    try {
      const userData = localStorage.getItem("userData");
      if (!userData) {
        throw new Error("User is not authenticated");
      }

      const token = JSON.parse(userData).token;
      if (!token) {
        throw new Error("User is not authenticated");
      }

      const requestObject = {
        request: `clients/${client.id}`,
        name: clientName,
        clientType: clientType,
        llsNumber: llsNumber,
        billingEmail: clientBillingEmail,
      };

      await fetchData(requestObject, "PUT", token);
      setToastType("success");
      setToastMessage("הלקוח נערך בהצלחה");
      setShowToast(true);
      fetchClients();
      closeModal();
    } catch (err) {
      console.error(err.message);
      setToastType("danger");
      setToastMessage("לא ניתן לערוך את הלקוח");
      setShowToast(true);
      fetchClients();
      closeModal();
    }
  };

  // FUNCTION TO HANDLE CLOSE BTN MODAL
  const closeModal = () => {
    setIsOpen(false);
    setClientName("");
    setClientType(1);
    setClientNameError(false);
    setClientIDError(false);
    setClientBillingEmailError(false);
    setEmailErrorMessage(""); // Reset email error message
    setClient(null);
  };

  return (
    <Modal show={isOpen} onHide={() => closeModal()} centered>
      <Modal.Header closeButton>
        <Modal.Title>{client ? "עריכת לקוח" : "יצירת לקוח חדש"}</Modal.Title>
      </Modal.Header>
      <Form noValidate onSubmit={handleSubmit}>
        <Modal.Body>
          <div className="create_station_modal--wrapper">
            <Form.Group className="mb-3" controlId="stationName">
              <div className="mb-1">
                <span className="lead text-primary font-weight-bold">
                  שם הלקוח
                </span>
              </div>
              <Form.Control
                type="text"
                value={clientName}
                placeholder="הכנס את שם הלקוח"
                onChange={(e) => {
                  setClientName(e.target.value);
                  setClientNameError(false); // Reset error when typing
                }}
                required
                className={clientNameError ? "input-error" : "input-regular"}
              />
              {clientNameError && (
                <span className="text-danger text-sm">שדה חובה</span>
              )}
            </Form.Group>

            {/* New Dropdown for Company Type */}
            <Form.Group className="mb-3" controlId="companyType">
              <div className="mb-1">
                <span className="lead text-primary font-weight-bold">
                  סוג החברה
                </span>
              </div>
              <Form.Control
                as="select"
                value={clientType}
                onChange={(e) => setClientType(Number(e.target.value))}
              >
                <option value={1}>חברה פרטית</option>
                <option value={2}>חברה ציבורית</option>
                <option value={3}>עוסק מורשה</option>
              </Form.Control>
            </Form.Group>
            <Form.Group className="mb-3" controlId="clientID">
              <div className="mb-1">
                <span className="lead text-primary font-weight-bold">
                  מספר מזהה
                </span>
              </div>
              <Form.Control
                type="text"
                value={llsNumber}
                placeholder="הכנס את מזהה הלקוח כמו ח.פ או ת.ז"
                onChange={(e) => {
                  setLlsNumber(e.target.value);
                  setClientIDError(false); // Reset error when typing
                }}
                required
                maxLength={15}
                className={clientIDError ? "input-error" : "input-regular"}
              />
              {clientIDError && (
                <span className="text-danger text-sm">שדה חובה</span>
              )}
            </Form.Group>

            <Form.Group className="mb-3" controlId="clientBillingEmail">
              <div className="mb-1">
                <span className="lead text-primary font-weight-bold mb-0">
                  דוא״ל הנה״ח
                </span>
              </div>
              <Form.Control
                type="email"
                value={clientBillingEmail}
                placeholder="הכנס דוא״ל עבור הנהלת חשבונות"
                onChange={(e) => {
                  setClientBillingEmail(e.target.value);
                  setClientBillingEmailError(false); // Reset error when typing
                  setEmailErrorMessage(""); // Reset email error message when typing
                }}
                required
                style={{ textAlign: "right" }}
                className={
                  clientBillingEmailError ? "input-error" : "input-regular"
                }
              />
              {clientBillingEmailError && (
                <span className="text-danger text-sm">{emailErrorMessage}</span>
              )}
            </Form.Group>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => closeModal()}>
            סגירה
          </Button>
          <Button variant="success" type="submit">
            המשך
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
