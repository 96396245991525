import TripsTable from "./tripsTable/tripsTable";
import { Container } from "react-bootstrap";

const Trips = () => {
  return (
    <Container className="mb-5">
      <h2 className="font-weight-bold">נסיעות</h2>
      <p className="p-0 m-0">ניהול כל הנסיעות, הוספה, הסרה, ועוד.</p>
      <p className="p-0 m-0 pb-3">
        מה זה אומר? כל נסיעה שתוקם במערכת הסדרנים יכולו לראות אותה ולקחת אותה
        במידה ואין נהג.
      </p>

      <TripsTable />
    </Container>
  );
};

export default Trips;
