import { fetchData } from "../../utils/fetchData";

export const handleLogout = (navigate, setLoading) => {
  setLoading(true);

  let dataObj = {
    request: "organizers/logout",
  };

  fetchData(dataObj, "POST")
    .then(() => {
      console.log("Logout successful");
      clearUserData();
      navigate("/login");
    })
    .catch((error) => {
      console.error("Logout error", error);
      clearUserData();
      navigate("/login");
    })
    .finally(() => {
      setLoading(false);
    });
};

export const clearUserData = () => {
  localStorage.removeItem("userData");
  localStorage.removeItem("token");
  sessionStorage.clear();
  console.log("User data cleared.");
};
