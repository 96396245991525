import React, { useEffect, useState } from "react";
import { Container, Table, Pagination } from "react-bootstrap";
import { fetchData } from "../../utils/fetchData";
import LoaderComponent from "../../components/LoaderComponent/LoaderComponent";

const ActivitiesPage = () => {
  const [activities, setActivities] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  // Function to retrieve the token from localStorage
  const getToken = () => {
    const userData = localStorage.getItem("userData");
    if (userData) {
      const { token } = JSON.parse(userData);
      return token;
    }
    return null;
  };

  const fetchActivities = async (page) => {
    setLoading(true);
    const token = getToken(); // Retrieve the token dynamically

    if (!token) {
      console.error("No token found, please log in.");
      setLoading(false);
      return;
    }

    const dataObj = {
      request: `activities?page=${page}&limit=50`, // Ensure the request is to the correct endpoint with pagination
    };

    try {
      const response = await fetchData(dataObj, "GET", token);
      setActivities(response.activities || []); // Update activities based on response data
      setTotalPages(response.totalPages || 1); // Update totalPages based on response data
    } catch (error) {
      console.error("Error fetching activities:", error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchActivities(currentPage);
  }, [currentPage]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <Container>
      <h2 className="my-4">Recent Activities</h2>
      {loading ? (
        <LoaderComponent />
      ) : (
        <>
         <div className="table-container">
         <div className="table-responsive">
          <Table striped bordered hover responsive>
            <thead className="thead-light sticky-header">

              <tr>
                <th>Admin</th>
                <th>Action</th>
                <th>Entity</th>
                <th>Description</th>
                <th>Timestamp</th>
              </tr>
            </thead>
            <tbody>
              {activities.map((activity) => (
                <tr key={activity._id}>
                  <td>{activity.user}</td>
                  <td>{activity.action}</td>
                  <td>{activity.entity}</td>
                  <td>{activity.description}</td>
                  <td>{new Date(activity.timestamp).toLocaleString()}</td>
                </tr>
              ))}
            </tbody>
          </Table>
          </div>
          </div>
          <Pagination>
            {[...Array(totalPages).keys()].map((number) => (
              <Pagination.Item
                key={number + 1}
                active={number + 1 === currentPage}
                onClick={() => handlePageChange(number + 1)}
              >
                {number + 1}
              </Pagination.Item>
            ))}
          </Pagination>
        </>
      )}
    </Container>
  );
};

export default ActivitiesPage;
