import React, { useEffect, useState } from "react";
import "./login.scss";
import { Form, Button, Alert, InputGroup } from "react-bootstrap";
import Logo from "../../assets/icons/logo.svg";
import { fetchData } from "../../utils/fetchData";
import { useLocation, useNavigate } from "react-router-dom";

const Login = () => {
  const [inputOtp, setInputOtp] = useState("");
  const [loading, setLoading] = useState(false);
  const [showErr, setShowErr] = useState(false);
  const [errMessage, setErrMessage] = useState("");
  const navigate = useNavigate();

  const location = useLocation();
  const phoneNumber = location.state?.phoneNumber || "";
  const lastFourDigits = phoneNumber?.slice(-4);

  useEffect(() => {
    if (!phoneNumber) {
      navigate("/login"); // Redirect to the login screen
    }
  }, [phoneNumber, navigate]);

  const handleSubmit = (event) => {
    setLoading(true);
    event.preventDefault();
    let dataObj = {
      request: "organizer/login",
      phoneNumber: phoneNumber,
      otp: inputOtp,
    };

    fetchData(dataObj, "POST")
      .then((response) => {
        setLoading(false);
        setShowErr(false);

        // Save user data to localStorage
        localStorage.setItem("userData", JSON.stringify(response));

        // Redirect to dashboard
        navigate("/dashboard");
      })
      .catch((error) => {
        setErrMessage(error.message);
        setShowErr(true);
        setLoading(false);
      });
  };

  const handlePassword = () => {
    console.log("Reset Password");
  };

  return (
    <div className="login-page">
      <div className="login__wrapper test-branch">
        <Form
          className="shadow p-4 bg-white rounded login__form"
          onSubmit={handleSubmit}
        >
          <img
            className="img-thumbnail mx-auto d-block mb-2 login__form__logo"
            src={Logo}
            alt="logo"
          />
          <div className="h5 mb-2 text-right">
            התחברות למערכת עם קוד חד-פעמי
          </div>
          <div className="mb-2 text-right">
            שלחנו SMS עם קוד חד-פעמי למספר טלפון נייד שמסתיים בספרות{" "}
            {lastFourDigits}
          </div>
          {showErr && <Alert variant="danger">{errMessage}</Alert>}

          <Form.Group className="mb-2" controlId="password">
            <Form.Label>קוד האימות</Form.Label>
            <InputGroup>
              <Form.Control
                type="tel"
                value={inputOtp}
                placeholder="הכנס את הקוד שקיבלת בהודעה"
                onChange={(e) => setInputOtp(e.target.value)}
                style={{ textAlign: "right" }}
              />
            </InputGroup>
          </Form.Group>

          <Form.Group className="mb-4" controlId="checkbox">
            <Form.Check type="checkbox" label="זוכר אותי בפעם הבאה" />
          </Form.Group>
          {!loading ? (
            <Button className="w-100" variant="primary" type="submit">
              המשך
            </Button>
          ) : (
            <Button className="w-100" variant="primary" type="submit" disabled>
              רק רגע...
            </Button>
          )}
          <div className="d-grid justify-content-end">
            <Button
              className="text-muted px-0"
              variant="link"
              onClick={handlePassword}
            >
              צריך עזרה?
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default Login;
