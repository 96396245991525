import React from "react";

const LoaderComponent = () => {
  return (
    <div
      className="d-flex justify-content-center
    align-items-center w-100 h-100"
    >
      <div
        className="spinner-grow text-primary"
        style={{ width: "3rem", height: "3rem" }}
        role="status"
      ></div>
    </div>
  );
};

export default LoaderComponent;
