import React from "react";
import { Toast, ToastContainer } from "react-bootstrap";

const ToastComponent = ({ showToast, setShowToast, type, message }) => {
  return (
    <ToastContainer position="bottom-center" className="p-3">
      <Toast
        onClose={() => setShowToast(false)}
        show={showToast}
        delay={3000}
        bg={type}
        autohide
      >
        <Toast.Body className="text-white text-center">{message}</Toast.Body>
      </Toast>
    </ToastContainer>
  );
};

export default ToastComponent;
