import React, { useState } from "react";
import { Nav, Modal, Button } from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";
import { navItems } from "./navItems";
import { handleLogout } from "./authHelpers";
import Logo from "../../assets/icons/logo.svg";
import "./Sidemenu.scss";

const SideMenu = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showLogoutModal, setShowLogoutModal] = useState(false);

  return (
    <>
      <Nav className="d-none d-md-block bg-light sidebar">
        <div className="sidebar__logo">
          <img src={Logo} alt="Logo" className="sidebar__logo__img" />
        </div>
        <div className="sidebar__content">
          {navItems.map((item, index) => (
            <Nav.Item key={index} className="nav-item">
              <NavLink
                to={item.to}
                className={({ isActive }) =>
                  isActive ? "nav-link active" : "nav-link"
                }
                end={item.end}
              >
                <div className="sidebar__item">
                  <div className="sidebar__item__icon">{item.icon}</div>
                  <span>{item.label}</span>
                </div>
              </NavLink>
            </Nav.Item>
          ))}
        </div>
        <div className="sidebar__footer">
          <Nav.Item className="nav-item">
            <Nav.Link
              onClick={() => setShowLogoutModal(true)}
              className="nav-link"
            >
              התנתק
            </Nav.Link>
          </Nav.Item>
        </div>
      </Nav>

      {/* Logout Modal */}
      <Modal
        show={showLogoutModal}
        onHide={() => setShowLogoutModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>אישור התנתקות</Modal.Title>
        </Modal.Header>
        <Modal.Body>האם אתה בטוח שברצונך להתנתק?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowLogoutModal(false)}>
            ביטול
          </Button>
          <Button
            variant="danger"
            onClick={() => {
              setShowLogoutModal(false);
              handleLogout(navigate, setLoading);
            }}
            disabled={loading}
          >
            התנתק
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SideMenu;
