import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Login from "./pages/login/login";
import OTP from "./pages/otp/otp";
import DashboardLayout from "./components/DashboardLayout/DashboardLayout";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/otp" element={<OTP />} />
        <Route
          path="/dashboard/*"
          element={<PrivateRoute component={DashboardLayout} />}
        />
        <Route path="*" element={<Navigate to="/login" />} />
      </Routes>
    </Router>
  );
};

const PrivateRoute = ({ component: Component }) => {
  const isAuthenticated = localStorage.getItem("userData");
  return isAuthenticated ? <Component /> : <Navigate to="/login" />;
};

export default App;
