import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import SideMenu from "../SideMenu/SideMenu";
import Dashboard from "../../pages/dashboard/dashboard";
import MobileTopMenu from "../../components/MobileTopMenu/MobileTopMenu";
import Settings from "../../pages/settings/settings";
import Clients from "../../pages/clients/clients";
import Trips from "../../pages/trips/trips";
import "./dashboardLayout.scss";
import "../../sass/base/_base.scss";
import ClientDetails from "../../pages/clients/ClientDetails/ClientDetails";
import ActivitiesPage from "../../pages/ActivitiesPage/ActivitiesPage";
import TripDetails from "../../pages/trips/tripsDetails/TripDetails";
import EnvironmentHeader from "../environmentHeader/EnvironmentHeader";

const DashboardLayout = () => {
  return (
    <Container fluid>
      <Row>
        <EnvironmentHeader />
        <MobileTopMenu />
        {/* Side Menu */}

        <Col md={2} className="d-none d-md-block side-menu">
          <SideMenu />
        </Col>

        {/* Content Area */}
        <Col md={10} className="offset-md-2 mt-2 mt-md-5">
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="trips" element={<Trips />} />
            <Route path="trip-details/:id" element={<TripDetails />} />
            <Route path="clients" element={<Clients />} />
            <Route path="activities" element={<ActivitiesPage />} />
            <Route path="/clients/:id" element={<ClientDetails />} />
            <Route path="settings" element={<Settings />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </Col>
      </Row>
    </Container>
  );
};

export default DashboardLayout;
